<template>
<v-container fluid class="pa-0">
    <PageHeader :contextStyle="contextStyle">
      <!-- <template #right><v-btn small><v-icon small color="success">mdi-file-excel</v-icon></v-btn></template> -->
    </PageHeader>

    <v-tabs v-if="( $route.params.clu_id !== undefined && parseInt( $route.params.clu_id ) >= 0 )" v-model="activeTab" grow light show-arrows="mobile" slider-size="1" class="mt-4" style="border-bottom-left-radius: 40px !important;">
        <v-tabs-slider color="green"></v-tabs-slider>
        <v-tab v-for="item in tabItems" :key="item.title" :disabled="item.disabled"
            class="pl-3 pr-1 pb-4" style="border-bottom-left-radius: 20px !important; background-color:white;">
            <span>
                <v-icon class="mr-1" :color="(activeTab.event == 0) ? 'red'  : 'grey'">{{item.icon}}</v-icon>
                <span class="hidden-sm-and-down">
                    {{ item.title }}
                </span>
            </span>
        </v-tab>
    </v-tabs>
    <UsersList v-if="activeTab === 1 && ( $route.params.clu_id !== undefined && parseInt( $route.params.clu_id ) >= 0 )" class="ml-6 mr-6" :clu_id="$route.params.clu_id"/>
    <ClusterForm v-if="activeTab === 0 && ( $route.params.clu_id !== undefined && parseInt( $route.params.clu_id ) >= 0 )"/>
    <DevicesList v-if="activeTab === 2 && ( $route.params.clu_id !== undefined && parseInt( $route.params.clu_id ) >= 0 )" :inSlider="false" :cluId="$route.params.clu_id" />
    <v-card v-if="( $route.params.clu_id == undefined )" class="ma-4" style="background-color: transparent !important;">
        <ClusterList />
    </v-card>

</v-container>
</template>

<script>
import PageHeader from '@/components/ui/PageHeader'
import ClusterList from '@/components/clusters/ClustersList'
import ClusterForm from '@/components/clusters/ClustersForm'
import UsersList from '@/components/users/UsersList'
import DevicesList from '@/components/devices/DeviceList'

//import { filterUpdateService } from '@/rxjsServices';

export default {
    name: 'clusters-page',
    props: ['contextStyle'],
    components: {
        PageHeader,
        ClusterList,
        ClusterForm,
        UsersList,
        DevicesList
    },
    data: function () {
        return {
            loading: { getXls: false, getTasks: false },         // Enregistrement en cours...
            items: [],
            enableAdd: true,
            modalEvent : false,
            activeTab: -1, 
            showTab: true,
            tabItems: [
                { title: 'Fiche',              disabled: false, icon: 'mdi-file-document-multiple-outline'},
                { title: 'Utilisateurs',       disabled: false, icon: 'mdi-folder-multiple-outline'},
                { title: 'Capteurs',           disabled: false, icon: '$devices'},
            ],
        }
    },
    computed: { },
    created(){},
    mounted: function (){
        //this.search = cacheGetters.getSearchBarItem('tasktypes') || ''
        //this.getItems()
    },
    methods: {
        async getItems(){
            this.items = []
        }
    },
    beforeDestroy() {
        //this.subTask.unsubscribe()
    },
}
</script>