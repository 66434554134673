<template>
<v-container fluid class="pa-0">
<!-- Formulaire -->
    <v-card fluid class="ma-2 pa-3 elevation-0 ilo-v-card">
    <v-card-title >
        Fiche de {{ item.lastName + ' ' + item.firstName }}
        <v-spacer></v-spacer>
        <v-chip outlined label color="secondary">
                <v-icon left>mdi-information-outline</v-icon>Modification: {{ $date(item.updatedAt).format('DD MMMM YYYY') }}
        </v-chip>
    </v-card-title>
    <v-layout row wrap class="layout-flex-2" style="">
    <v-flex xs4 class="pa-2">
        <v-text-field outlined dense class="" label="Nom"    v-model="item.lastName" ></v-text-field>
        <v-text-field outlined dense class="" label="Prénom" v-model="item.firstName"></v-text-field>
        <v-text-field outlined dense class="" label="Login"  v-model="item.username"></v-text-field>
        <v-text-field outlined dense class="" label="Mot de passe" type="password" v-model="item.password" loading>
            <template v-slot:progress>
                <v-progress-linear :value="progress" :color="color" height="3" absolute></v-progress-linear>
            </template>
        </v-text-field>
    </v-flex>
    <v-flex xs4 class="pa-2">
        <v-text-field outlined dense class="" label="Tel" v-model="item.phone"></v-text-field>
        <v-text-field outlined dense class="" label="Mobile" v-model="item.mobile"></v-text-field>
        <v-text-field outlined dense class="" label="Courriel" v-model="item.email"></v-text-field>
        <v-select outlined dense class="" :items="roleList" item-value="value" v-model="item.role" label="Role" single-line >
        </v-select>
    </v-flex>
    <v-flex xs4 class="pa-2">
        <v-btn v-if="$root.$permissions?.User?.update"  outlined color="primmary" class="mt-3" @click="resetPasswordMail()">
            <v-icon left>mdi-mail</v-icon>Envoyer le mail
        </v-btn>
    </v-flex>
    <v-flex xs4 class="pa-2">
        <AutocompleteMulti v-if="!loading.fetchData"
            :title="'Cluster autorisés'"
            :listObject.sync="item.clusterIds" :listParent.sync="listClustersAuth" 
            ressource="clusters"
            :fields="{ 
                index: 'clu_id', text:'clu_name', autofocus: true, outlined: true, dense: true, deletablechips: true,
                search:'clu_name', multiSelect:true, prefetch: prefetchCluster, params:'extended=viewall'
        }"></AutocompleteMulti>
    </v-flex>
    <v-flex xs4 class="pa-2">
        <AutocompleteMulti v-if="!loading.fetchData"
            :title="'Cluster actif'"
            :listObject.sync="item.clusterId" :listParent.sync="listClusters" 
            ressource="clusters"
            :fields="{ 
                index: 'clu_id', text:'clu_name', autofocus: true, outlined: true, dense: true,
                search:'clu_name', multiSelect:false, prefetch: prefetchCluster, params:'extended=viewall'
        }"></AutocompleteMulti>
    </v-flex>
    </v-layout>
    <v-card-actions>
        <v-btn class="" large color="red" v-if="item.uid" @click="deleteUser(item)"><v-icon title="Supprimer">mdi-delete</v-icon></v-btn>
        <v-spacer></v-spacer>
        <v-btn class="" large color="success" :loading="loading.fetchData" @click.native="saveUser()" :disabled="loading.fetchData">Enregistrer</v-btn>
        <v-btn class="" large color="secondary" href="/#/users/">Retour</v-btn>
    </v-card-actions>
</v-card>
</v-container>
</template>

<script>
import AutocompleteMulti from '@/components/ui/AutocompleteMulti'
import { mapState, mapActions } from 'pinia'
import { useUserStore } from '@/stores/user'
import { cacheGetters } from "@/stores/cache.module"

export default {
    name: 'User-Edit',
    components: { AutocompleteMulti },
    computed: {
        ...mapState(useUserStore, {item: 'item', roleList: 'getRolesArray' }),
        progress () {
            return (this.item.password) ? Math.min(100, this.item.password.length * 12.5) : 0
        },
        color () {
            return ['error', 'warning', 'success'][Math.floor(this.progress / 50)]
        },
        prefetchCluster(){
            return !(this.item.clusters.length > 0)
        }
    },
    data: function () {
        return {
            loading: { fetchData: true },
            cacheState: cacheGetters.getStates('GEN') || [],
            listClusters: [],
            listClustersAuth: []
        }
    },
    async mounted () {
        await this.fetchUser( this.$route.params.id, '?include=usercluster,clusters' )
        this.item.clusters    = (this.item.clusters) ? this.item.clusters : []
        this.listClustersAuth = this.item.clusters
        this.item.clusterIds = this.item.clusters.map(a => a.clu_id)

        if(this.item.usercluster){
            this.listClusters.push( this.item.usercluster )
        }

        this.loading.fetchData = false
    },
    methods: {
        ...mapActions(useUserStore, {fetchUser: 'fetchItem', updateItem: 'updateItem', storeItem: 'storeItem', resetPassword: 'resetPassword'}),
        async saveUser () {
            // Enregistrement du formulaire
            this.loading.fetchData = true
            if( parseInt( this.$route.params.id ) > 0 ){
                this.updateItem()
            } else {
                this.storeItem()
            }
            this.$router.push('users')
        },
        async resetPasswordMail(){
            await this.resetPassword( this.item.id )
            this.$root.$toast({ color: 'success', text: 'Mail envoyé !' })
        },
        getCacheType: cacheGetters.getTypes,
    }
}
</script>