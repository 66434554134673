<template>
    <v-card color="grey lighten-4" min-width="350px" elevation="0" class="pa-0 elevation-0 ilo-v-card" style="background-color: white">
        <v-toolbar color="secondary" dark class="elevation-1" height="45px">
            <v-btn icon><v-icon>mdi-chevron-down</v-icon></v-btn>
            <v-toolbar-title>Geolocaliser</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon color="" @click.native="switchGeolocate()"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text class="mt-2">
            <div id="map-geolocate" style="width: 100%; height: 500px;" class=""></div>
        </v-card-text>
        <v-divider light></v-divider>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary"  :loading="saveProgress" :disabled="saveProgress" @click="sendResult()">Valider</v-btn>
            <v-btn outlined color="grey" @click="switchGeolocate()">Fermer</v-btn>
        </v-card-actions>
    </v-card>
    </template>
    
<script>
import LfgMap     from '@/plugins/mapgeolocate'
var mapthis = { map: {} }
import { bus } from '@/plugins/bus'

export default {
    name: 'map-geolocate',
    props: ['latLng', 'switchGeolocate'],
    computed: {

    },
    data: function () {
        return {
            saveProgress: false,         // Enregistrement en cours...
            markerLatLng : {}
        }
    },
    async mounted() {
        mapthis.map = new LfgMap('map-geolocate')
        bus.$on('marker:moved', (obj) => {
            this.markerLatLng = obj
        })
        setTimeout( () => {
            mapthis.map.display( [46.099537484204, 2.5624986257931], 7, 'EPSG3857' )
            mapthis.map.addLocMarker(this.latLng)
        }, 1000)

    },
    methods: {
        adresseFromPoint(latLng){
            return new Promise( (resolve) => {
                this.$http.customRequest( {
                    headers: '', baseURL: '', method: 'get', url: "https://api-adresse.data.gouv.fr/reverse/?lon=" + latLng.lng + "&lat=" + latLng.lat + ""
                }).then( (response) => {
                    if( response.data && response.data.features && response.data.features[0] ){

                        this.markerLatLng.address = response.data?.features[0]?.properties.name
                        this.markerLatLng.town    = response.data?.features[0]?.properties.city
                        this.markerLatLng.zip     = response.data?.features[0]?.properties.postcode
                        resolve('resolved')
                    }
                }).catch( (error) => {
                    resolve(error)
                })
            })
        },
        async sendResult(){
            this.saveProgress = true
            await this.adresseFromPoint(this.markerLatLng)
            this.switchGeolocate(this.markerLatLng)
            this.saveProgress = false
        }
    },
    beforeDestroy() {
        bus.$off('marker:moved')
        
    }
}
</script>

<style lang="scss" scoped>
@import "../../../node_modules/leaflet/dist/leaflet.css";
@import '../../assets/leaflet.css';
@import '../../assets/map.css';

</style>