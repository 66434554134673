<template>
<v-container fluid class="pa-0">
    <PageHeader :contextStyle="contextStyle"></PageHeader>
<!-- Formulaire -->
    <v-card fluid class="ma-2 pa-5 elevation-2">
        <v-layout row wrap>
        <v-flex xs12 class="pa-2">
            <v-text-field label="Nom" v-model="form.sta_label"></v-text-field>
        </v-flex>
        <v-flex xs6 class="pa-2">
            <v-text-field label="Identifiant état" v-model="form.sta_id" :disabled="modify" ></v-text-field>
        </v-flex>
        <v-flex xs6 class="pa-2">
            <v-menu offset-y content-class="elevation-0" style="z-index:900">
                <template v-slot:activator="{ on }">
                    <v-btn :color="form.sta_color" block dark class="mt-3" v-on="on"><v-icon left>mdi-palette</v-icon>Couleur</v-btn>
                </template>
                <v-color-picker v-model="form.sta_color" class="ma-2 elevation-0" hide-canvas :swatches="swatches" show-swatches></v-color-picker>
            </v-menu>
        </v-flex>
        <v-flex xs6 class="pa-2">
            <v-text-field label="Entité" v-model="form.sta_entity"></v-text-field>
        </v-flex>
        <v-flex xs6 class="pa-2">
            <v-select :items="visibility" item-value="value" v-model="form.sta_visibility" label="Statut" single-line ></v-select>
        </v-flex>
        </v-layout>
        <div class="text-right">
            <v-btn color="success" :loading="saveProgress" @click.native="saveForm()" :disabled="saveProgress">
            Enregistrer
            </v-btn>
            <v-btn class="ml-2" color="secondary" href="/#/states/">
            Retour
            </v-btn>
        </div>
    </v-card>
</v-container>
</template>

<script>
import PageHeader from '@/components/ui/PageHeader'
import { cacheMutations } from "@/stores/cache.module"

export default {
    name: 'states-edit',
    props: ['contextStyle'],
    components: { PageHeader },
    data: function () {
        return {
            saveProgress: false,         // Enregistrement en cours...
            visibility: [
                {'value': 1,    'text': 'Visible'},
                {'value': 0,    'text': 'Désactivé'}
            ],
            modify: false,
            form:{
              sta_id: null, sta_entity: '',
              sta_label: '', sta_visibility: 1, sta_color: '#2196F3', 
              sta_created_at: ''
            },
            swatches: [
                ['#FF0000', '#AA0000', '#550000'], ['#FFFF00', '#AAAA00', '#555500'], ['#00FF00', '#00AA00', '#005500'],
                ['#00FFFF', '#00AAAA', '#005555'], ['#0000FF', '#0000AA', '#000055'],
            ],
        }
    },
    computed: {
        color () {
            return ['error', 'warning', 'success'][Math.floor(this.progress / 50)]
        }
    },
    mounted:function (){
        if( ( this.$route.params.sta_id ) && this.$route.params.sta_id.length > 3 ){
            this.modify = true
            this.getItem()
        }
    },
    methods: {
        getItem() {
            this.$http.get( '/states/' + this.$route.params.sta_id )
            .then( (response) => {
                var prop
                for( prop in response.data.data ){
                    if( this.form[prop] !== undefined ){
                        this.form[prop] = response.data.data[prop] 
                    }
                }
            })
            .catch( (error) => {
                this.$root.$toast({ color: 'warning', text: error })
            })
        },
        saveForm () {
            // Enregistrement du formulaire
            this.saveProgress = true
            if( this.$route.params.sta_id.length > 3 ){
                this.$http.put( '/states/' + this.$route.params.sta_id, this.form )
                .then( () => {
                    this.$root.$toast({ color: 'success', text: 'Fiche enregistrée !' })
                    this.getStates()
                    this.$router.push({ path: '/states' })
                }).catch( (error) => {
                    this.$root.$toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
                })
            } else {
                this.$http.post( '/states/', this.form )
                .then( () => {
                    this.$root.$toast({ color: 'success', text: 'Fiche enregistrée !' })
                    this.$router.push({ path: '/states' })
                }).catch( (error) => {
                    this.$root.$toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
                    this.saveProgress = false
                })
            }
        },
        getStates () {
        return new Promise( (resolve, reject) => {
            this.$http.get( '/states/?per_page=false' ).then( (response) => {
            let states = {}
            response.data.data.forEach( (item) => {
                if( states[ item.sta_id.substring(0, 3) ] == undefined ){
                states[ item.sta_id.substring(0, 3) ] = {}
                }
                states[ item.sta_id.substring(0, 3) ][item.sta_id] = item
            })
            cacheMutations.setStates( states )
            resolve('resolved')
            }).catch( () => { 
            reject('failed')
            })
        })
        },
    }
}
</script>