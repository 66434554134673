<template>
<v-app id="login" light>
  <v-layout style="margin-top:1em;">
    <v-flex v-if="!splashScreen">
      <v-hover>
        <template v-slot:default="{ hover }">
          <v-card :elevation="hover ? 12 : 6" class="pa-2 mx-auto transition-swing" style="max-width: 420px;">
            <v-card-title >
              <v-img :src='images.logoSmall' contain class="mx-auto" :aspect-ratio="16/10" alt='logo'></v-img>
            </v-card-title>
            <v-card-text>
              <div v-if="!forgetPass" style="width:100%;">
                <v-form v-model="valid">
                  <v-text-field class="pt-1 login-input" @focus="scrollUp()"
                    @keyup.enter="passFocus()"
                    label="Identifiant" v-model="formData.login"
                    prepend-icon="mdi-account"
                    :rules="nameRules" required lazy-validation>
                  </v-text-field>
                  <v-text-field class="pt-1"
                    ref="passwordField"
                    label="Mot de passe" 
                    @focus="scrollUp()"
                    @keyup.native.enter="connect()"
                    v-model="formData.password"
                    :type="'password'"
                    prepend-icon="mdi-lock"
                    :error-messages="errorMessage"
                    :error="badAuth">
                  </v-text-field>
                  <v-btn small :outlined="!formData.persist" :color="(formData.persist) ? 'primary' : 'grey'" 
                    class="ml-0 mr-0 pa-1" style="min-width: 25px" @click="formData.persist = !formData.persist">
                    <v-icon v-show="formData.persist">mdi-check-bold</v-icon>
                  </v-btn>
                  <v-btn small text color="grey" @click="formData.persist = !formData.persist" class="ml-0">Se souvenir de moi</v-btn>
                  <v-btn small text color="red" @click="forgetPass = true" class="ml-0">Mot de passe oublié</v-btn>
                </v-form>
              </div>
              <div v-if="forgetPass" style="width:100%;">
                <v-form v-model="valid">
                  <v-text-field class="pt-1 login-input" @focus="scrollUp()"
                    @keyup.enter="passFocus()"
                    label="Identifiant" v-model="formData.login"
                    prepend-icon="mdi-account"
                    :rules="nameRules" required lazy-validation>
                  </v-text-field>
                </v-form>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-btn v-if="!loginControl && !forgetPass" color="orange" text href="#/signin/">Créer un compte</v-btn>
              <v-spacer v-if="!loginControl"></v-spacer>
              <v-btn v-if="!loginControl && !forgetPass" color="primary" elevation="0"
                :disabled="(formData.password.length == 0 || formData.login.length == 0)" @click="onSubmit">
                Se connecter
              </v-btn>
              <v-progress-linear v-if="loginControl" :indeterminate="true"></v-progress-linear>
              <v-alert v-if="!loginControl && linkSend" dense text type="success" elevation="2">
                Un lien de renouvellement à été envoyé sur votre adresse mail.
              </v-alert>
              <v-btn v-if="!loginControl && forgetPass && !linkSend" color="primary" elevation="0"
                :disabled="(formData.login.length == 0)" @click="resetPassword">
                Ré-initialiser le mot de passe
              </v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-hover>
    </v-flex>
    <v-flex v-if="splashScreen" class="mt-1">
      <v-row align="center" justify="center">
        <v-img :src='images.logoSmall' alt='logo' max-height="150" contain></v-img>
      </v-row>
      <div class="text-center text-h2 font-weight-bold mt-9">Chargement des données</div>
      <v-progress-linear v-if="splashScreen" :indeterminate="true"></v-progress-linear>
    </v-flex>
  </v-layout>
</v-app>
</template>

<script>
import { actions, state } from "@/stores/auth.module"

export default {
  name: 'login-component',
  data () {
    return {
      checkAuth: false,
      badAuth: false,
      errorMessage: [],
      formData : {
        login: '',
        password: '',
        persist: false,
        //_path: 'auth',
        //_method: 'GET'
      },
      valid: false,
      nameRules: [ (v) => !!v || 'Identifiant obligatoire' ],
      passRules: [ (v) => !!v || 'Pass obligatoire' ],
      images: {
        logoSmall: require('@/assets/logo_' + process.env.VUE_APP_NAME + '.svg')
      },
      forgetPass: false,
      linkSend: false,
      splashScreen: false
    }
  },
  computed: {
      loginControl() {
        return state.authenticating
      },
      samlRedirect() {
        const uri = `?RelayState=${encodeURIComponent( process.env.VUE_APP_URL + '#/login' )}`
        let samlIpd = process.env.VUE_APP_SAML_IDP || 'test'
        return process.env.VUE_APP_API_URL + 'saml2/' + samlIpd + '/login' + uri
      }
  },
  mounted:function (){
      if( ( this.$route.params.authcode ) && this.$route.params.authcode.length > 3 ){
        actions.login({login: this.formData.login, password: this.$route.params.authcode, grant_type: 'password'}) //authorization_code
      }
  },
  methods: {
    connect() {
      this.onSubmit()
    },
    login(){
      return actions.login()
    },
    async onSubmit() {
      this.formData.login    = this.formData.login.toLowerCase().trim()
      this.formData.password = this.formData.password.trim()
      if (this.formData.login != '' && this.formData.password != '') {
        let loginIn
        loginIn = await actions.login({login: this.formData.login, password: this.formData.password}, false)
        this.formData.password = ""
        if( loginIn ){
          this.splashScreen = true
          //setTimeout( () => {
            this.$router.push(this.$router.history.current.query.redirect || '/')
          //}, 2500)
        } else {
          this.badAuth = true
          this.errorMessage.push('Login ou mot de passe erroné')
          setTimeout( () => {
            this.badAuth = false
            this.errorMessage = []
          }, 2500)
        }
      }
    },
    async resetPassword(){
      this.$http.customRequest({
          method: 'post',
          url: '/resetmypass',
          data: this.formData
      }).then( () => {
        this.forgetPass = true
        this.linkSend = true
      })
      .catch( () => {
          this.errorMessage.push('Votre autorisation a expirée')
      })
    },
    passFocus(){
      this.$refs.passwordField.focus()
    },
    scrollUp(){
      var el = this.$el.getElementsByClassName("login-input")[0];
      el.scrollIntoView();
    }
  },
  components: { }
}
</script>

<style lang="css">
/* Personnalisation code */
.login-container {
  transform: translate(0, -50px);
  padding-top: 2em;
}
.login-paper {
  width: 24em;
  text-align: center;
  margin-right: auto;
  margin-left: auto;
}
.login-logo {
  margin-bottom: 1.2em;
  padding-top: 0.5em;
}

</style>