<template>
    <v-card fluid class="ma-2 pa-5 elevation-2">
        <v-layout row wrap>
        <v-flex xs12 class="pa-2">
            <v-text-field label="Nom" v-model="item.typ_name"></v-text-field>
        </v-flex>
        <v-flex xs6 class="pa-2">
            <v-select :items="entityList" v-model="item.typ_entity" 
                item-text="name" item-value="name" label="Statut" single-line :disabled="modify"
                @change="item.typ_id = entityList.find(x => x.name === item.typ_entity).id" >
            </v-select>
            <!-- <v-text-field label="Entité"  v-model="item.typ_entity" :disabled="modify"></v-text-field> -->
        </v-flex>
        <v-flex xs6 class="pa-2">
            <v-select :items="visibility" item-value="value" v-model="item.typ_visibility" label="Statut" single-line></v-select>
        </v-flex>
        <v-flex xs6 class="pa-2">
            <v-text-field label="Identifiant type"    v-model="item.typ_id" :disabled="modify" ></v-text-field>
        </v-flex>
        <v-flex xs3 class="pa-2">
            <v-text-field label="Icone"    v-model="item.typ_icon" ></v-text-field>
        </v-flex>
        <v-flex xs3 class="pa-2">
            <v-menu offset-y content-class="elevation-0" style="z-index:900">
                <template v-slot:activator="{ on }">
                    <v-btn :color="item.typ_color" block dark class="mt-3" v-on="on">Couleur</v-btn>
                </template>
                <v-color-picker v-model="item.typ_color" class="ma-2 elevation-0" hide-canvas :swatches="swatches" show-swatches></v-color-picker>
            </v-menu>
        </v-flex>
        <v-flex xs12 class="pa-2">
            <v-textarea label="Description" rows="2" v-model="item.typ_description" ></v-textarea>
        </v-flex>
        </v-layout>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text href="/#/types/"><v-icon left dark>mdi-close</v-icon>Annuler</v-btn>
            <v-btn color="success" class="ml-3" :loading="loading.fetchData" @click.native="saveItem()" :disabled="loading.fetchData">
                <v-icon left dark>mdi-content-save-outline</v-icon>Enregistrer
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
//import AutocompleteMulti from '@/components/ui/AutocompleteMulti'
import { mapActions, mapState, mapWritableState } from 'pinia'
import { useTypeStore } from '@/stores/type'

export default {
    name: 'types-edit',
    props: [ 'contextStyle'],
    data: function () {
        return {
            loading: { fetchData: true },
            visibility: [
                {'value': 1,    'text': 'Visible'},
                {'value': 0,    'text': 'Désactivé'}
            ],
            modify: false,
            swatches: [
                ['#FF0000', '#AA0000', '#550000'], ['#FFFF00', '#AAAA00', '#555500'], ['#00FF00', '#00AA00', '#005500'],
                ['#00FFFF', '#00AAAA', '#005555'], ['#0000FF', '#0000AA', '#000055'],
            ],
        }
    },
    computed: {
        ...mapWritableState(useTypeStore, {item: 'item', itemList: 'itemList' }),
        ...mapState(useTypeStore, {entityList: 'entityList' })
    },
    async mounted (){
        await this.fetchItem( this.$route.params.typ_id )
        this.loading.fetchData = false

    },
    methods: {
        ...mapActions(useTypeStore, {fetchItem: 'fetchItem', updateItem: 'updateItem', storeItem: 'storeItem'}),
        async saveItem () {
                // Enregistrement du formulaire
                this.loading.fetchData = true
                if( this.$route.params.typ_id.length > 3 ){
                    this.updateItem()
                } else {
                    this.storeItem()
                }
                this.$router.push({ path: '/types' })
        },
    },
}
</script>