<template>
    <v-data-table v-bind:headers="headers" :items="itemList" item-key="id" :search="search" style="background-color: transparent !important;"
        items-per-page-text="Lignes par page" hide-default-header
        :items-per-page-options="pagination.rowsPerItem" class="mt-0">
        <template v-slot:top>
            <v-row class="ma-0 pa-0" v-if="(searchBar == undefined || searchBar) || (addItemButton == undefined || addItemButton)">
                <v-col cols="6" md="6" class="pa-1 pl-0">
                    <v-text-field v-if="(searchBar == undefined || searchBar)" append-icon="mdi-magnify" label="Recherche" outlined dense clearable single-line
                                hide-details v-model="search" @blur="saveSearchToCache()" class="pa-2 pl-0">
                    </v-text-field>
                </v-col>
                <v-col cols="1" md="1" class="pt-3 pa-1 pl-0">
                    <v-btn 
                        x-small fab depressed :color="(bookmarkFilter) ? 'amber' : 'grey darken-1'"
                        class="ma-1" @click="bookmarkFilter = !bookmarkFilter; loadDevices()"><v-icon>mdi-star</v-icon>
                    </v-btn>
                </v-col>
                <v-col cols="5" md="5" class="text-right pa-1" v-if="(addItemButton == undefined || addItemButton)">
                    <router-link :to="{ name: 'dev-slide-device-edit', params: { dev_uid: 'nouveau' } }">
                        <v-btn small elevation="0" color="primary">Ajouter un capteur</v-btn>
                    </router-link>
                </v-col>
            </v-row>
        </template>
        <template v-slot:[`item.dev_name`]="{ item }">
            <v-chip label class="ma-2" color="secondary">{{ item.dev_name }}</v-chip>
        </template>
        <template v-slot:[`item.dev_uid`]="{ item }">
            <v-btn icon :color="(item.usp_usr_id) ? 'yellow': 'grey'" @click="(item.usp_usr_id) ? detachDevice(item) : attachDevice(item)"><v-icon>mdi-star</v-icon></v-btn> {{ item.dev_uid }}
        </template>
        <template v-slot:[`item.dev_sta_id`]="{ item }">
            <v-hover v-slot="{ hover }">
                <v-btn small outlined label :color="stateListById[item.dev_sta_id].sta_color" @click="changeDeviceState(item)" :style="(!hover) ? '' : 'background-color: #fff; opacity: 1 !important;'">
                    {{ stateListById[item.dev_sta_id].sta_label }}
                </v-btn>
            </v-hover>
        </template>
        <template v-slot:[`item.dev_id`]="{ item }">
            <router-link :to="{ name: 'dev-slide-device-edit', params: { dev_uid: item.dev_uid } }">
                <v-hover v-slot="{ hover }">
                <v-btn color="#c2d33e" :outlined="(hover)" depressed elevation="0" dark :style="(!hover) ? 'border: solid 1px #fff;' : 'background-color: #fff; transparent:0;'">
                    <v-icon left >{{item.sit_id}} mdi-pencil</v-icon> Fiche
                </v-btn>
                </v-hover>
            </router-link>
        </template>
        <template v-slot:no-data>
        <v-alert :value="( !itemList.lenght )" color="error" icon="mdi-alert">
            Aucune donnée
        </v-alert>
        </template>
    </v-data-table>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useDeviceStore } from '@/stores/device'
import { useTypeStore } from '@/stores/type'
import { useStateStore } from '@/stores/state'

import { filterUpdateService } from '@/rxjsServices'

export default {
    name: 'devices-list',
    props: [ 'searchBar', 'addItemButton', 'inSlider', 'cluId' ],
    computed: {
        ...mapState(useDeviceStore, {itemList: 'itemList' }),
        ...mapState(useTypeStore, {typeListByGroup: 'itemListByGroup', typeListById: 'itemListById' }),
        ...mapState(useStateStore, {stateListByGroup: 'itemListByGroup', stateListById: 'itemListById' }),
    },
    data: function () {
        return {
            search: '',
            pagination: { totalItems: 0, rowsPerPage: 10, rowsPerItem: [15,25,35,{text:'Tout',value:-1}] },
            headers: [
                { text: 'Nom',   value: 'dev_name', align: 'left'},
                { text: 'ID',    value: 'dev_uid', align: 'left'},
                { text: 'Etat',  value: 'dev_sta_id', align: 'left'},
                { text: 'Action',value: 'dev_id', align: 'right'},
            ],
            bookmarkFilter: false
        }
    },
    created(){
        // modification au niveau du filtre
        this.subscribeFilter = filterUpdateService.getFilter().subscribe(message => {
            if (message) {
                this.loadDevices()
            }
        })
    },
    async mounted () {
        this.loadDevices()
    },
    methods: {
        ...mapActions(useDeviceStore, {fetchItems: 'fetchItems', updateItem: 'updateItem'}),
        async loadDevices(){
            let option = (this.bookmarkFilter) ? '&min_usp_usr_id=1' : ''
            if( this.cluId && this.cluId > 0 ){
                option += '&dev_clu_id=' +this.cluId
            }
            await this.fetchItems('?per_page=false&extended=withdevicepin' + option)
        },
        attachDevice(item){
            this.$http.put('/devices/' + item.dev_id + '/attachdevice').then( () => {
                this.loadDevices()
            })
        },
        detachDevice(item){
            this.$http.put('/devices/' + item.dev_id + '/detachdevice').then( () => {
                this.loadDevices()
            })
        },
        async changeDeviceState(item){
            let newStatut = (item.dev_sta_id == 'GEN01') ? 'GEN02' : 'GEN01'
            this.$root.$confirm('Confirmer', 'Confirmer la modification en ' + this.stateListById[newStatut].sta_label, { color: 'red', width: 400 }).then((confirm) => {
                if( confirm ){
                    item.dev_sta_id = (item.dev_sta_id == 'GEN01') ? 'GEN02' : 'GEN01'
                    this.updateItem(item)
                }
            })
            //this.loadDevices()
        }
    },
}
</script>