<template>
    <v-data-table v-bind:headers="headers" :items="itemList" item-key="sta_id" :search="search" style="background-color: transparent !important;"
        items-per-page-text="Lignes par page" :items-per-page-options="pagination.rowsPerItem" class="mt-0">
        <template v-slot:top>
            <v-row class="ma-0 pa-0"> <!-- style="background-color: #FAFAFA" -->
                <v-col cols="6" md="6" class="pa-1 pl-0">
                <v-text-field append-icon="mdi-magnify" label="Recherche" outlined dense clearable single-line
                            hide-details v-model="search" @blur="saveSearchToCache()" class="pa-2 pl-0">
                </v-text-field>
                </v-col>
                <v-col cols="6" md="6" class="text-right pa-2">
                    <v-btn color="success" depressed class="white--text" to="/clusters/0">
                        <v-icon left dark>mdi-pencil-plus-outline</v-icon>
                        <span class="hidden-sm-and-down">Créer</span>
                    </v-btn>
                </v-col>
            </v-row>
        </template>

        <template v-slot:[`item.clu_sta_id`]="{ item }">
            <v-chip small outlined label :color="stateListById[item.clu_sta_id].sta_color">
                {{ stateListById[item.clu_sta_id].sta_label }}
            </v-chip>
        </template>
        <template v-slot:[`item.usr_id_count`]="{ item }">
            <v-badge bordered color="error" overlap :content="(item.usr_id_count > 0) ? item.usr_id_count : '0'">
                <v-icon large >mdi-account</v-icon>
            </v-badge>
        </template>
        <template v-slot:[`item.dev_id_count`]="{ item }">
            <v-badge bordered color="error" overlap :content="(item.dev_id_count > 0) ? item.dev_id_count : '0'">
                <v-icon large >$devices</v-icon>
            </v-badge>
        </template>
        <template v-slot:[`item.clu_created_at`]="{ item }">
            <v-chip label outlined>{{ $date(item.clu_created_at).format('DD/MM/YYYY') }}</v-chip>
        </template>
        <template v-slot:[`item.clu_id`]="{ item }">
            <v-btn color="orange" elevation="0" dark :href="'/#/clusters/' + item.clu_id + ''">
                <v-icon left class="mr-2">mdi-pencil</v-icon>Ouvrir
            </v-btn>
        </template>

        <template slot="pageText" slot-scope="{ pageStart, pageStop }">
        De {{ pageStart }} à {{ pageStop }}
        </template>
    </v-data-table>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useClusterStore } from '@/stores/cluster'
import { useTypeStore } from '@/stores/type'
import { useStateStore } from '@/stores/state'

export default {
    name: 'clusters_list',
    props: [ ],
    computed: {
        ...mapState(useClusterStore, {itemList: 'itemList' }),
        ...mapState(useTypeStore, {typeListByGroup: 'itemListByGroup', typeListById: 'itemListById' }),
        ...mapState(useStateStore, {stateListByGroup: 'itemListByGroup', stateListById: 'itemListById' }),
    },
    data: function () {
        return {
            loading: { getgetTaskItems: false },
            validFrom: true,
            items        : [],
            search: '',
            pagination: { totalItems: 0, rowsPerPage: 10, rowsPerItem: [15,25,35,{text:'Tout',value:-1}] },
            headers: [
                { text: 'Nom',     align: 'left', value: 'clu_name'},
                { text: 'Statut',  align: 'left', value: 'clu_sta_id'},
                { text: 'Utilisateur(s)', align: 'center', value: 'usr_id_count'},
                { text: 'Capteur(s)', align: 'center', value: 'dev_id_count'},
                { text: 'Creation',  align: 'left', value: 'clu_created_at'},
                { text: 'Action',  align: 'right', value: 'clu_id'},
            ],
        }
    },
    mounted: function (){
        this.fetchItems('?per_page=false&extended=viewall,withcountuser,withcountdevice')
    },
    methods: {
        ...mapActions(useClusterStore, {fetchItems: 'fetchItems'}),
    },
}
</script>