import ApiService from '@/services/api'
import { defineStore } from 'pinia'
//import { taskUpdateService } from '@/rxjsServices';

const ENDPOINT = '/devices/'
const ITEM_ID  = 'dev_uid'

export const useDeviceStore = defineStore('devices', {
  // convert to a function
  state: () => ({
    item: {
        dev_uid: '', dev_clu_id: 0, dev_macid: '', dev_sta_id: 'GEN01', dev_typ_id: 'DEV01', dev_name: '', dev_fw_rev: '', dev_serial: '',
        dev_customfields: {}, _pivot: { devices: [], users: [] }
    },
    itemList: []
  }),
  getters: {

  },
  actions: {
    fetchItem (id, options) {
        return new Promise( (resolve, reject) => {
            options = (options) ? '/' + options : ''
            if( id && id.length > 8 ){
                ApiService.get(ENDPOINT + id + options).then( (response) => {
                    if( !response.data.data.dev_customfields || Object.prototype.toString.call( response.data.data.dev_customfields ) === '[object Array]' ) {
                        response.data.data.dev_customfields = {}
                    }
                    this.SET_ITEM(response.data.data)
                    resolve(this.item)
                }).catch( (error) => { 
                    reject(error)
                })
            } else {
                this.RESET_ITEM()
                resolve(this.item)
            }
        })
    },
    fetchItems (options) {
        return new Promise( (resolve, reject) => {
            options = (options) ? options : ''
            ApiService.get( ENDPOINT + options ).then( (response) => {
                this.metaTask = response.data.meta
                if( Object.prototype.toString.call( response.data.data ) === '[object Array]' ) {
                    this.SET_ITEMS(response.data.data)
                }
                resolve('resolved')
            }).catch( (error) => { 
                reject(error)
            })
        })
    },
    storeItem () {
        return new Promise( (resolve, reject) => {
            ApiService.post( ENDPOINT, this.item ).then( (response) => {
                this.SET_ITEM(response.data)
                resolve(this.item)
            }).catch( (error) => { 
                reject(error)
            })
        })
    },
    updateItem (item) {
        return new Promise( (resolve, reject) => {
            if(item){
                this.SET_ITEM(item)
            }
            ApiService.put( ENDPOINT + this.item[ITEM_ID], this.item ).then( (response) => {
                this.SET_ITEM(response.data)
                resolve(this.item)
            }).catch( (error) => { 
                reject(error)
            })
        })
    },
    destroyItem (id) {
        return new Promise( (resolve, reject) => {
            this.item[ITEM_ID] = (id) ? id : this.item[ITEM_ID]
            ApiService.delete(ENDPOINT + this.item[ITEM_ID]).then( () => {
                this.RESET_ITEM()
                resolve('resolved')
            }).catch( (error) => { 
                reject(error)
            })
        })
    },
    downloadItems(options, fileName){
        return new Promise( (resolve, reject) => {
            options = (options)  ? options : ''
            fileName= (fileName) ? fileName : 'rapport'
            ApiService.customRequest({ method: 'get', url: '/report/tasks/?' + options, responseType: 'blob' } ).then( (response) => {
                //this.$download(response.data, fileName + ".xlsx", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
                resolve(response.data)
            }).catch( (error) => { 
                reject(error)
            })
        })
    },
    //***************************MUTATIONS ***************************************/
    SET_ITEM (item) {
      this.item = item
      this.item._pivot= {}
      this.item._pivot['users'] = (this.item.users) ? [...this.item.users.map(a => a['id'])] : []
    },
    SET_ITEMS (itemList) {
      this.itemList = itemList
    },
    RESET_ITEM () {
        this.item = {
            dev_updated_at: null,
            dev_customfields : {},
            dev_typ_id: 'DEV01',
            _pivot: { devices: [], users: [] } 
        }
    }
  }
})
