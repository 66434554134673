<template>
<v-container fluid class="pa-0">
    <PageHeader :contextStyle="contextStyle"></PageHeader>

    <v-card class="ma-4" style="background-color: transparent !important;">
    <v-data-table v-bind:headers="headers" :items="itemList" item-key="sta_id" :search="itemSearchList" style="background-color: transparent !important;"
        items-per-page-text="Lignes par page" 
        :items-per-page-options="pagination.rowsPerItem">
        <template v-slot:top>
            <v-row class="ma-0 pa-0">
                <v-col cols="6" md="6" class="pa-1">
                <v-text-field append-icon="mdi-magnify" label="Recherche" outlined dense clearable single-line
                            hide-details v-model="itemSearchList" class="pa-2">
                </v-text-field>
                </v-col>
                <v-col cols="6" md="6" class="text-right pa-2">
                    <v-btn color="success" depressed class="white--text" to="/states/0">
                        <v-icon left dark>mdi-pencil-plus-outline</v-icon>
                        <span class="hidden-sm-and-down">Créer</span>
                    </v-btn>
                </v-col>
            </v-row>
        </template>

        <template v-slot:body="{ items }">
        <tbody>
        <tr v-for="item in items" :key="item.sta_id" style="cursor:pointer;">
            <td class="pt-2 text-xs-left">
                <strong flat color="blue" class="ma-0 subheading text-uppercase blue--text">{{ item.sta_id }}</strong>
                &nbsp;<span flat color="blue" class="ma-0 subheading text-capitalize blue--text"></span>
            </td>
            <td class="text-xs-left">{{ item.sta_label }}</td>
            <td class="text-xs-left">
                <v-chip :active="true" outlined small label color="primary" >{{ item.sta_entity }}</v-chip>
            </td>
            <td class="text-xs-left">
                <v-chip :active="true" outlined small label :color="(!item.sta_visibility) ? 'red' : item.sta_color" >
                    {{ (item.sta_visibility) ? 'Visible' : 'Désactivé' }}
                </v-chip>
            </td>
            <td class="text-right">
                <span class="caption text--disabled font-italic">Créé le </span>
                <span class="caption text--primary font-italic">{{ $date(item.sta_created_at).format('DD MMMM YYYY') }}</span>
                <v-btn depressed class="ml-2" color="amber darken-1" :href="'/#/states/' + item.sta_id + ''">
                    <v-icon left class="mr-2">mdi-pencil</v-icon>Modifier
                </v-btn>
            </td>
        </tr>
        </tbody>
        </template>
        <template slot="pageText" slot-scope="{ pageStart, pageStop }">
        De {{ pageStart }} à {{ pageStop }}
        </template>
    </v-data-table>
    </v-card>
</v-container>
</template>

<script>
import { mapState, mapWritableState, mapActions } from 'pinia'
import { useStateStore } from '@/stores/state'
//import { cacheGetters, cacheMutations } from "@/stores/cache.module"
import PageHeader from '@/components/ui/PageHeader'

export default {
    name: 'states-page',
    props: ['toast', 'contextStyle'],
    components: {
        PageHeader
    },
    data: function () {
        return {
            saveProgress: false,         // Enregistrement en cours...
            search: '',
            pagination: { totalItems: 0, rowsPerPage: 10, rowsPerItem: [15,25,35,{text:'Tout',value:-1}] },
            headers: [
            { text: 'Id', align: 'left', value: 'sta_id'},
            { text: 'Libellé', align: 'left', value: 'sta_label'},
            { text: 'Type', value: 'sta_entity' },
            { text: 'Statut', value: 'sta_visibility' },
            { text: 'Modification', align: 'right', value: 'sta_updated_at' }
            ],
        }
    },
    computed: {
        ...mapState(useStateStore, {itemList: 'itemList' }),
        ...mapWritableState(useStateStore, { itemSearchList: 'itemSearchList' }),
        pages () {
            if (this.pagination.rowsPerPage == null || this.pagination.totalItems == null ) return 0
            return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
        }
    },
    async mounted () {
        if( !this.itemList.length ){
            await this.fetchItemList('?per_page=false')
        }
    },
    methods: {
        ...mapActions(useStateStore, {fetchItemList: 'fetchItemList', saveSearchItemList: 'SET_ITEM_SEARCH_LIST'}),
        handlePage(index){
            this.pagination.limit = this.pagination.rowsPerPage * (index - 1)
        },
    }
}
</script>