<template>
    <v-data-table v-bind:headers="headers" :items="items" item-key="id"
        items-per-page-text="Lignes par page" 
        :items-per-page-options="pagination.rowsPerItem">
        <template v-slot:top>
            <v-row class="ma-0 pa-0">
                <v-col cols="6" md="6" class="pa-1">
                </v-col>
            </v-row>
        </template>

        <template v-slot:[`item.name`]="{ item }"><v-chip label class="ma-2" color="secondary">{{ item.name }}</v-chip><v-icon>mdi-arrow-right</v-icon></template>
        <template v-slot:[`item.clusterId`]="{ item }">
            <v-select v-if="!loading.fetchData" :items="clusters" item-text="clu_name" item-value="clu_id" v-model="item.clusterId" label="Cluster" single-line></v-select>
        </template>
        <template v-slot:[`item.id`]="{ item }">
            <v-btn color="orange" elevation="0" dark @click="inject(item)">
                <v-icon left >{{item.id}} mdi-pencil</v-icon> Injecter
            </v-btn>
        </template>

        <template slot="pageText" slot-scope="{ pageStart, pageStop }">
        De {{ pageStart }} à {{ pageStop }}
        </template>
    </v-data-table>
</template>

<script>
import { mapState } from 'pinia'
import { useTypeStore } from '@/stores/type'

export default {
    name: 'tasktype-edit',
    computed: {
        ...mapState(useTypeStore, {itemList: 'itemList' }),
    },
    data: function () {
        return {
            loading: { fetchData: true },
            pagination: { totalItems: 0, rowsPerPage: 10, rowsPerItem: [15,25,35,{text:'Tout',value:-1}] },
            items: [],
            clusters: [],
            headers: [
                { text: 'Données', align: 'left', value: 'name'},
                { text: 'Cluster,', align: 'right', value: 'clusterId' },
                { text: 'Modification', align: 'right', value: 'id' }
            ],
        }
    },
    async mounted () {
        this.$http.get('/demos/?per_page=false').then( (response) => {
            this.items = response.data.data
        })
        this.$http.get('/clusters/?per_page=5').then( (response) => {
            this.clusters = response.data.data
            this.loading.fetchData = false
        })
    },
    methods: {
        inject(item){
            this.$http.post('/demos/', item).then( () => {
                this.$root.$toast({ color: 'success', text: 'Données injectées !' })
            })
        }
    },
}
</script>