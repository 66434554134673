<template>
    <v-data-table v-bind:headers="headers" :items="itemList" item-key="sta_id" :search="itemSearchList" style="background-color: transparent !important;"
        items-per-page-text="Lignes par page" 
        :items-per-page-options="pagination.rowsPerItem">
        <template v-slot:top>
            <v-row class="ma-0 pa-0">
                <v-col cols="6" md="6" class="pa-1">
                <v-text-field append-icon="mdi-magnify" label="Recherche" outlined dense clearable single-line
                            hide-details v-model="itemSearchList" class="pa-2">
                </v-text-field>
                </v-col>
            </v-row>
        </template>

        <template v-slot:body="{ items }">
        <tbody>
        <tr v-for="item in items" :key="item.typ_id" style="cursor:pointer;">
            <td class="pt-2 text-xs-left">
                <strong flat color="blue" class="ma-0 subheading text-uppercase blue--text">{{ item.typ_id }}</strong>
                &nbsp;<span flat color="blue" class="ma-0 subheading text-capitalize blue--text"></span>
            </td>
            <td class="text-xs-left font-weight-bold">{{ item.typ_name }}</td>
            <td class="text-xs-left">
                <v-chip :active="true" outlined small label :color="item.typ_color" >{{ item.typ_entity }}</v-chip>
            </td>
            <td class="text-xs-left">
                <v-chip :active="true" small label dark :color="(item.typ_visibility) ? 'green' : 'greu'" >
                    {{ (item.typ_visibility) ? 'ACTIF' : 'INACTIF' }}
                </v-chip>
            </td>
            <td class="text-right">
                <span class="caption text--disabled font-italic">Créé le </span>
                <span class="caption text--primary font-italic">{{ $date(item.typ_created_at).format('DD MMMM YYYY') }}</span>
                <v-btn depressed class="ml-2" color="amber darken-1" :href="'/#/types/' + item.typ_id + ''">
                    <v-icon left class="mr-2">mdi-pencil</v-icon>Modifier
                </v-btn>
            </td>
        </tr>
        </tbody>
        </template>
        <template slot="pageText" slot-scope="{ pageStart, pageStop }">
        De {{ pageStart }} à {{ pageStop }}
        </template>
    </v-data-table>
</template>

<script>
import { mapState, mapWritableState, mapActions } from 'pinia'
import { useTypeStore } from '@/stores/type'

export default {
    name: 'tasktype-edit',
    computed: {
        ...mapState(useTypeStore, {itemList: 'itemList' }),
        ...mapWritableState(useTypeStore, {itemSearchList: 'itemSearchList' }),
    },
    data: function () {
        return {
            pagination: { totalItems: 0, rowsPerPage: 10, rowsPerItem: [15,25,35,{text:'Tout',value:-1}] },
            headers: [
            { text: 'Id', align: 'left', value: 'typ_id'},
            { text: 'Nom', align: 'left', value: 'typ_name'},
            { text: 'Type', value: 'typ_entity' },
            { text: 'Statut', value: 'typ_visibility' },
            { text: 'Modification', align: 'right', value: 'typ_updated_at' }
            ],
        }
    },
    async mounted () {
        await this.fetchItemList('?per_page=false')
    },
    methods: {
        ...mapActions(useTypeStore, {fetchItemList: 'fetchItemList'}),
    },
}
</script>