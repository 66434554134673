<template>
<v-container>
    <v-row>
        <v-col cols="6" md="6">
            <img v-if="(planId == 'SUB01')" width="210" :src="logo.plan1" style="cursor:pointer;" class="ml-1" />
            <img v-if="(planId == 'SUB02')" width="210" :src="logo.plan2" style="cursor:pointer;" class="ml-1" />
        </v-col>
        <v-col cols="6" md="6" class="text-right">
            <span v-if="(planId == 'SUB01')" style="color:white;" class="subtitle-1">Particulier</span><br />
            <span v-if="(planId == 'SUB02')" style="color:white;" class="subtitle-1">Entreprise</span><br />
        </v-col>
    </v-row>

    <!-- <v-app-bar class="mt-2 ml-0" color="transparent" min-height="220" shrink-on-scroll light short elevation="0" style="z-index:100 !important;">
        <v-app-bar-title>
            <img v-if="(planId == 'SUB01')" width="210" :src="logo.plan1" style="cursor:pointer;" class="ml-1" />
            <img v-if="(planId == 'SUB02')" width="210" :src="logo.plan2" style="cursor:pointer;" class="ml-1" />
        </v-app-bar-title>
        <v-spacer></v-spacer>
        <span v-if="(planId == 'SUB01')" style="color:white;" class="subtitle-1">Particulier</span><br />
        <span v-if="(planId == 'SUB02')" style="color:white;" class="subtitle-1">Entreprise</span><br />
    </v-app-bar> -->
    <v-container v-if="(planId == 'SUB01')">
        <span style="color:white;" class="subtitle-1">Fonctions :</span><br />
        <span style="color:white;" class="subtitle-2">
            <ul>
                <li>Connecté en Wi-Fi sur votre espace personnel, vous pouvez suivre votre consommation sous la douche pour constater les économies que vous allez réaliser !</li>
            </ul>
        </span>
    </v-container>
    <v-container v-if="(planId == 'SUB02')">
        <span style="color:white;" class="subtitle-1">Fonctions :</span><br />
        <span style="color:white;" class="subtitle-2">
            <ul>
                <li>Connecté en Wi-Fi sur votre espace personnel, vous pouvez suivre votre consommation sous la douche pour constater les économies que vous allez réaliser !</li>
            </ul>
    </span>
    </v-container>
</v-container>
</template>

<script>
export default {
    name: 'sign-plan-component',
    props: ['planId'],
    components: { },
    data () {
        return {
            logo: {
                plan2: require('@/assets/logo_signin.svg'),
                plan3: require('@/assets/logo_' + process.env.VUE_APP_NAME + '.svg'),
                plan4: require('@/assets/logo_' + process.env.VUE_APP_NAME + '.svg'),
                plan1: require('@/assets/logo_signin.svg'),
            },
        }
    },
    mounted:function (){

    }
}
</script>