import ApiService from '@/services/api'
import { defineStore } from 'pinia'
//import { taskUpdateService } from '@/rxjsServices';

const ENDPOINT = '/clusters/'
const ITEM_ID  = 'clu_id'

export const useClusterStore = defineStore('clusters', {
  // convert to a function
  state: () => ({
    clu_name: '',
    clu_address: '',
    clu_zip: '',
    clu_town: '',
    clu_lat: null,
    clu_lng: null,
    item: { clu_customfields: {} },
    itemList: []
  }),
  getters: {

  },
  actions: {
    fetchItem (id, options) {
        return new Promise( (resolve, reject) => {
            options = (options) ? '/' + options : ''
            if( id && id > 0 ){
                ApiService.get(ENDPOINT + id + options).then( (response) => {
                    if( !response.data.data.clu_customfields || Object.prototype.toString.call( response.data.data.clu_customfields ) === '[object Array]' ) {
                        response.data.data.clu_customfields = {}
                    }
                    this.SET_ITEM(response.data.data)
                    resolve(this.item)
                }).catch( (error) => { 
                    reject(error)
                })
            } else {
                this.RESET_ITEM()
                resolve(this.item)
            }
        })
    },
    fetchItems (options) {
        return new Promise( (resolve, reject) => {
            ApiService.get( ENDPOINT + options ).then( (response) => {
                this.metaTask = response.data.meta
                if( Object.prototype.toString.call( response.data.data ) === '[object Array]' ) {
                    this.SET_ITEMS(response.data.data)
                }
                resolve('resolved')
            }).catch( (error) => { 
                reject(error)
            })
        })
    },
    storeItem () {
        return new Promise( (resolve, reject) => {
            ApiService.post( ENDPOINT, this.item ).then( (response) => {
            this.SET_ITEM(response.data.data)
            resolve(this.item)
            }).catch( (error) => { 
            reject(error)
            })
        })
    },
    updateItem () {
        return new Promise( (resolve, reject) => {
            ApiService.put( ENDPOINT + this.item[ITEM_ID], this.item ).then( (response) => {
            this.SET_ITEM(response.data)
            resolve(this.item)
            }).catch( (error) => { 
            reject(error)
            })
        })
    },
    destroyItem (id) {
        return new Promise( (resolve, reject) => {
            this.item[ITEM_ID] = (id) ? id : this.item[ITEM_ID]
            ApiService.delete(ENDPOINT + this.item[ITEM_ID]).then( () => {
                this.RESET_ITEM()
            resolve('resolved')
            }).catch( (error) => { 
            reject(error)
            })
        })
    },
    //***************************MUTATIONS ***************************************/
    SET_ITEM (item) {
      this.item = item
    },
    SET_ITEMS (itemList) {
      this.itemList = itemList
    },
    RESET_ITEM () {
        this.item = {
          clu_name: '',
          clu_lat: null,
          clu_lng: null,
          clu_sta_id: 'GEN01', clu_customfields : {
            clu_address: '',
            clu_zip: '',
            clu_town: '',
            clu_lat: null,
            clu_lng: null,
          }
        }
      }
  }
})
