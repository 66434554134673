<template>
    <v-container fluid class="pa-0">
    <!-- Formulaire -->
    <v-card fluid class="ma-2 pa-3 elevation-0 ilo-v-card">
        <v-layout row wrap class="layout-flex-2 mt-4">
            <v-row no-gutters>
            <v-col cols="12" md="12" class="pr-2">
                <v-text-field outlined dense class="" label="Dénomination sociale"  v-model="item.clu_name"></v-text-field>
            </v-col>
            <!-- Adresse ------------------->
            <v-col cols="11" md="11" class="pr-2">
                <v-autocomplete
                    v-model.trim="searchAddress"
                    label="Rechercher adresse"
                    :items="addressTab"
                    item-value="id" item-text="label"
                    :search-input.sync="search"
                    @change="selectAdress" @focus="searchBoxClosed = false" @blur="searchBoxClosed = true"
                    append-icon="mdi-magnify" filled outlined dense clearable no-filter
                    class="inline shrink expanding-search"
                    background-color="white">
                </v-autocomplete>
            </v-col>
            <v-col cols="1" md="1" class="pr-2">
                <v-btn outlined color="secondary" class="mb-2" @click="modalGeolocate = !modalGeolocate" title="Localiser manuellement">
                    <v-icon>mdi-map-marker-radius-outline</v-icon>
                </v-btn>
            </v-col>
            <!-- right side -->
            <v-col cols="12" md="6" class="pr-2">
                <v-text-field ref="address" outlined dense class="" label="Adresse / complément" v-model="item.clu_address"></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="pr-2">
                <v-text-field outlined dense class="" label="Ville" v-model="item.clu_town"></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="pr-2">
                <v-text-field outlined dense class="" label="Code postal" v-model="item.clu_zip"></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="pr-2">
                <v-divider></v-divider>
            </v-col>
            <v-col cols="12" md="6" class="pr-2">
                <v-text-field outlined dense class="" label="Adresse de facturation (si différente)"  v-model="item.clu_customfields.clu_address"></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="pr-2">
                <v-text-field outlined dense class="" label="Complément adresse"  v-model="item.clu_customfields.clu_address_comp"></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="pr-2">
                <v-text-field outlined dense class="" label="Ville" v-model="item.clu_customfields.clu_town"></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="pr-2">
                <v-text-field outlined dense class="" label="Code postal" v-model="item.clu_customfields.clu_zip"></v-text-field>
            </v-col>


            <v-col cols="12" md="6" class="pr-2">
                <v-text-field outlined dense class="" label="Adresse e-mail" v-model="item.clu_mail"></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="pr-2" ref="mobile">
                <v-text-field outlined dense class="" label="Tel. portable" v-model="item.clu_phone"></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="pr-2">
                <v-text-field outlined dense class="" label="Tel. Fixe" v-model="item.clu_customfields.clu_phone"></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="pr-2">
                <v-text-field outlined dense class="" label="TVA Intracommunautaire"  v-model="item.clu_customfields.clu_tvaintra"></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="pr-2">
                <v-text-field outlined dense class="" label="Siret"  v-model="item.clu_siret"></v-text-field>
            </v-col>
            <!-- -------------------------- -->
            <v-col cols="12" md="6" class="pr-2" v-if="$root.$permissions?.Company?.delete">
                <v-select outlined dense class=""
                    item-text="sta_label" item-value="sta_id"
                    :items="stateListByGroup['GEN']" v-model="item.clu_sta_id" label="Statut" single-line >
                </v-select>
            </v-col>
        </v-row>
        </v-layout>
    </v-card>
    <v-toolbar extended flat color="transparent" class="elevation-0" >
        <v-btn :disabled="!item.clu_id"  color="error" @click.native="deleteItem()"><v-icon left dark>mdi-close</v-icon>Supprimer</v-btn>
        <v-spacer></v-spacer>
        <v-btn text href="/#/clusters/"><v-icon left dark>mdi-close</v-icon>Annuler</v-btn>
        <v-btn color="success" class="ml-3" :loading="loading.saveProgress" @click.native="saveItem()" :disabled="loading.saveProgress">
            <v-icon left dark>mdi-content-save-outline</v-icon>Enregistrer
        </v-btn>
    </v-toolbar>

    <v-dialog v-model="modalGeolocate" hide-overlay max-width="900" content-class="ilo-v-card">
    <MapGeolocate v-if="modalGeolocate" :latLng="{lat: item.clu_lat, lng: item.clu_lng}" :switchGeolocate="switchGeolocate" />
</v-dialog>
</v-container>
</template>

<script>
import MapGeolocate from '@/components/map/MapGeolocatePopup'

import { mapState, mapWritableState, mapActions } from 'pinia'
import { useClusterStore } from '@/stores/cluster'

import { useTypeStore } from '@/stores/type'
import { useStateStore } from '@/stores/state'

export default {
    name: 'clusters-edit',
    props: [ ],
    components: { MapGeolocate },
    data: function () {
        return {
            loading: { fetchData: false, saveProgress: false },
            search: null, searchAddress: null, searchBoxClosed: true, addressTab: [], timeOutHandle: 'off',
            modalGeolocate: false
        }
    },
    watch: {
        search (val) {
            this.searchWord = val
            if( this.timeOutHandle == 'off' && val && val.length >= 3 ){
                this.timeOutHandle = 'on'
                clearTimeout(this.searchTempo)
                this.searchTempo = setTimeout( () => {
                    this.loadAddress(this.searchWord)
                    this.timeOutHandle = 'off'
                }, 1500)
            }
        },
    },
    computed: {
        ...mapWritableState(useClusterStore, {item: 'item' }),
        ...mapState(useTypeStore, {typeListByGroup: 'itemListByGroup', typeListById: 'itemListById' }),
        ...mapState(useStateStore, {stateListByGroup: 'itemListByGroup', stateListById: 'itemListById' }),
    },
    async mounted (){
        await this.fetchItem( this.$route.params.clu_id, '' ) //?clu_sta_id=GEN01
        this.loading.fetchData = false
    },
    methods: {
        ...mapActions(useClusterStore, {fetchItem: 'fetchItem', updateItem: 'updateItem', storeItem: 'storeItem', destroyItem: 'destroyItem'}),
        async saveItem () {
            // Enregistrement du formulaire
            this.loading.fetchData = true
            if( parseInt( this.$route.params.clu_id ) > 0 ){
                this.updateItem()
            } else {
                this.storeItem()
            }
            this.$router.push({ path: '/clusters' })
        },
        async deleteItem(){
            this.$root.$confirm('Supprimer', 'Confirmer la suppression ?', { color: 'red', width: 400 }).then((confirm) => {
                if( confirm ){
                    this.item.clu_sta_id = 'TSK07'
                    this.destroyItem()
                    this.$root.$toast({ color: 'error', top:false, bottom:true, right:false, left:true, text:'Tache supprimée !' })
                    this.$router.push({ path: '/clusters' })
                }
            })
        },
        loadAddress (val) {
            if( val.length > 1 ){
                this.$http.customRequest( {
                    headers: '',
                    baseURL: '',
                    method: 'get',
                    url: "https://api-adresse.data.gouv.fr/search/?q=" + val + "&limit=6"
                })
                .then( (response) => {
                    let data = []
                    let address, key
                    for (key = 0; (address = response.data.features[key]) !== undefined; key++) {
                        data[key] = { label: address.properties.label, value: address.properties.id, id:key, item: address }
                    }
                    this.addressTab = data
                })
            } else {
                return false
            }
        },
        selectAdress(){
            if( this.searchAddress !== null && this.searchAddress !== undefined ){
                this.item.clu_customfields.clu_address = this.addressTab[this.searchAddress]?.item?.properties?.name
                this.item.clu_customfields.clu_town = this.addressTab[this.searchAddress]?.item?.properties?.city
                this.item.clu_customfields.clu_zip = this.addressTab[this.searchAddress]?.item?.properties?.postcode
                this.item.clu_customfields.clu_lat = this.addressTab[this.searchAddress]?.item?.geometry?.coordinates[1]
                this.item.clu_customfields.clu_lng = this.addressTab[this.searchAddress]?.item?.geometry?.coordinates[0]
                this.item.clu_lat= this.item.clu_customfields.clu_lat
                this.item.clu_lng = this.item.clu_customfields.clu_lng
            }
            this.searchAddress = ''
            this.addressTab.splice(0)
            this.$refs.address.focus()
        },
        adresseFromPoint(latLng){
            this.$http.customRequest( {
                headers: '',
                baseURL: '',
                method: 'get',
                url: "https://api-adresse.data.gouv.fr/reverse/?lon=" + latLng.lng + "&lat=" + latLng.lat + ""
            }).then( (response) => {
                if( response.data && response.data.features && response.data.features[0] ){

                    this.item.clu_customfields.clu_address = response.data?.features[0]?.properties.name
                    this.item.clu_customfields.clu_town    = response.data?.features[0]?.properties.city
                    this.item.clu_customfields.clu_zip     = response.data?.features[0]?.properties.postcode
                    this.item.clu_customfields.clu_lat     = latLng.lat
                    this.item.clu_customfields.clu_lng     = latLng.lng
                    this.item.clu_lat     = latLng.lat
                    this.item.clu_lng     = latLng.lng
                }
            })
        },
        switchGeolocate(latLng){
            if( latLng && latLng.lat ){
                this.item.clu_lat     = latLng.lat
                this.item.clu_lng     = latLng.lng
                if( !this.item.clu_customfields.clu_address  ){
                    this.item.clu_customfields.clu_address = latLng.address
                    this.item.clu_customfields.clu_town    = latLng.town
                    this.item.clu_customfields.clu_zip     = latLng.zip
                    this.item.clu_customfields.clu_lat     = latLng.lat
                    this.item.clu_customfields.clu_lng     = latLng.lng
                }
            }
            this.modalGeolocate = false
        },
    }
}
</script>