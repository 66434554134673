<template>
<v-app id="singin" light style="background-color: #b6c9c0;">
    <v-row class="ma-5">
        <v-col cols="12" md="5" class="pa-0" style="background-color: #088598;">
            <SignInPlan :planId="item.clu_typ_id" />
        </v-col>
        <v-col cols="12" md="7" class="pa-0" style="background-color: #ffffff;">
            <v-card fluid class="ma-4 pa-0 elevation-0">
                <v-card-title class="d-md-none justify-center text-uppercase font-weight-bold text-h6">Création de compte</v-card-title>
                <v-card-title class="d-none d-md-block justify-center text-uppercase font-weight-bold text-h4">Création de compte</v-card-title>
            </v-card>
            <v-card v-if="!signSuccess" fluid class="ma-4 pa-0 elevation-0">
                <v-card-title class="justify-center text-uppercase grey--text font-weight-bold">Vos identifiants :</v-card-title>
                <v-row class="ma-2">
                <v-col cols="12" md="6"   class="pa-0 pr-2"><v-text-field outlined dense class="" color="red" label="Nom"  v-model="item.firstName"></v-text-field></v-col>
                <v-col cols="12" md="6"   class="pa-0 pr-2"><v-text-field outlined dense class="" label="Prénom"  v-model="item.lastName"></v-text-field></v-col>
                <v-col cols="12" md="12"  class="pa-0 pr-2"><v-text-field outlined dense class="" prepend-inner-icon="mdi-email-outline" label="Email"  v-model="item.email"></v-text-field></v-col>
                <!--<v-flex xs6 class="pl-6 pr-6">
                    <v-text-field outlined dense class="" prepend-inner-icon="mdi-lock-outline" label="Mot de passe" type="password" v-model="item.password" loading>
                    <template v-slot:progress>
                    <v-progress-linear :value="progress" :color="color" height="3" absolute class="mt-2"></v-progress-linear>
                    </template>
                    </v-text-field>
                </v-flex>-->
                <v-col cols="12" md="12" class=""><v-divider></v-divider></v-col>
                </v-row>

                <v-card-title class="mt-3 justify-center text-uppercase grey--text font-weight-bold">Informations :</v-card-title>
                <v-row class="">
                <v-col cols="12" md="8" class="pt-0 pb-0">
                    <v-text-field outlined dense class="" label="Nom"  v-model="item.clu_name"></v-text-field>
                </v-col>
                <v-col cols="12" md="4" class="pt-0 pb-0">
                    <v-text-field outlined dense class="" label="Siret"  v-model="item.clu_siret"></v-text-field>
                </v-col>
                <!-- Adresse ------------------->
                <v-col cols="12" md="12" class="pt-0 pb-0">
                    <v-autocomplete
                        v-model.trim="searchAddress"
                        label="Adresse"
                        :items="addressTab"
                        item-value="id" item-text="label"
                        :search-input.sync="search"
                        @change="selectAdress" @focus="searchBoxClosed = false" @blur="searchBoxClosed = true"
                        append-icon="mdi-magnify" filled outlined dense clearable no-filter
                        class="inline shrink expanding-search"
                        background-color="white">
                    </v-autocomplete>
                </v-col>
                <v-col cols="12" md="12" class="pt-0 pb-0">
                    <v-select item-text="label" item-value="value" outlined dense class=""
                        :items="cacheState" v-model="item.clu_typ_id" label="Type de compte" single-line persistent-hint hint="Type de compte">
                    </v-select>
                </v-col>
                <v-col cols="12" md="6" class="pt-0 pb-0">
                    <v-text-field outlined dense class="" label="Captcha" v-model="item.captcha"></v-text-field>
                </v-col>
                <v-col cols="7" md="4" class="pb-6" style="text-align: right;">
                    <img v-if="captchaReady" ref="imgSrc" :src="captchaImageSrc" />
                </v-col>
                <v-col cols="4" md="2" class="pb-6">
                    <v-btn depressed color="grey-lighten" min-height="45" class="ml-2" @click.native="getCaptcha()" title="Recharger le code">
                        <v-icon left dark>mdi-reload</v-icon>
                    </v-btn>
                </v-col>
                </v-row>

                <v-card-actions extended flat color="transparent" class="justify-center">
                    <v-btn color="orange" class="mr-3 ml-3" :loading="loading.fetchData" href="#/login" >
                        Retour
                    </v-btn>
                    <v-btn color="primary" class="mr-3 ml-3" :loading="loading.fetchData" @click.native="register()" :disabled="!validForm">
                        Valider
                    </v-btn>
                </v-card-actions>
            </v-card>
            <v-card v-if="signSuccess" fluid class="ma-4 pa-0 elevation-1">
                <v-card-title class="grey--text font-weight-bold" style="word-break: keep-all;">
                    Vous avez reçu un e-mail avec un lien pour terminer votre inscription.
                </v-card-title>
            </v-card>
        </v-col>
    </v-row>
    <ToastDialog ref="toast" />
</v-app>
</template>
    
<script>
import ToastDialog  from '@/components/ui/Toast.vue'
import SignInPlan  from '@/layouts/SignPlans.vue'

export default {
    name: 'singin-component',
    components: { ToastDialog, SignInPlan },
    data () {
        return {
            toastDefault: { color: '', top:false, bottom:true, right:true, left:false, text:'Fiche enregistré' },
            toastOption:  { color: '', top:false, bottom:true, right:true, left:false, text:'Fiche enregistré' },
            loading: { fetchData: false, saveProgress: false },
            item: {
                firstName: '', lastName: '', email: '', password: '',
                clu_name: '', clu_siret: '', clu_typ_id: 'FO', clu_customfields: {},
                captcha: ''
            },
            cacheState: [ 
                { label: 'Particulier', id: 'PA', value: 'SUB01' },
                { label: 'Entreprise',  id: 'EN', value: 'SUB02' },
            ],
            search: null, searchAddress: null, searchBoxClosed: true, addressTab: [], timeOutHandle: 'off',
            captchaImageSrc: '',
            captchaReady: false,
            signSuccess: false
        }
    },
    watch: {
        search (val) {
            this.searchWord = val
            if( this.timeOutHandle == 'off' && val && val.length >= 3 ){
                this.timeOutHandle = 'on'
                clearTimeout(this.searchTempo)
                this.searchTempo = setTimeout( () => {
                    this.loadAddress(this.searchWord)
                    this.timeOutHandle = 'off'
                }, 1500)
            }
        },
    },
    computed: {
        validForm(){
            let valid = true
            valid = (!this.item.firstName.length > 2) ? false : valid
            valid = (!this.item.lastName.length > 0) ? false : valid
            valid = (!this.item.email.length > 0) ? false : valid
            //valid = (!this.item.password.length > 0) ? false : valid
            valid = (!this.item.captcha.length > 0) ? false : valid
            valid = (this.loading.fetchData) ? false : valid
            return valid
        },
        progress () {
            return (this.item.password) ? Math.min(100, this.item.password.length * 12.5) : 0
        },
        color () {
            return ['error', 'warning', 'light-green accent-4'][Math.floor(this.progress / 50)]
        },
    },
    mounted:function (){
        this.$root.$toast       = this.$refs.toast.open
        this.item.clu_typ_id = 'SUB01'
        this.getCaptcha()
    },
    methods: {
        register(){
            return new Promise( (resolve, reject) => {
                this.item.clu_name = ( this.item.clu_name.length > 0 ) ? this.item.clu_name : this.item.firstName
                this.item.username = this.item.email
                //var formFile = new FormData()
                //for (var prop in this.item) {
                //    if( prop !== 'obj_file' ){
                //        formFile.append(prop, this.item[prop])
                //    }
                //}
                //if( this.item.obj_file ) {
                //    formFile.append('docfile', this.item.obj_file, this.item.obj_file.name)
                //}
                this.$http.post( '/register/', this.item ).then( () => {
                    //this.$router.push({ path: '/login' })
                    this.signSuccess = true
                    this.$root.$toast({ text: 'Inscription envoyée avec succès !' })
                    resolve('resolved')
                }).catch( (error) => {
                    this.$root.$toast({ text: 'Erreur lors de l\'enregistrement !' , error: error })
                    reject(error)
                })
            })
        },
        loadAddress (val) {
            if( val.length > 1 ){
                this.$http.customRequest( {
                    headers: '',
                    baseURL: '',
                    method: 'get',
                    url: "https://api-adresse.data.gouv.fr/search/?q=" + val + "&limit=6"
                })
                .then( (response) => {
                    let data = []
                    let address, key
                    for (key = 0; (address = response.data.features[key]) !== undefined; key++) {
                        data[key] = { label: address.properties.label, value: address.properties.id, id:key, item: address }
                    }
                    this.addressTab = data
                })
            } else {
                return false
            }
        },
        selectAdress(){
            if( this.searchAddress !== null && this.searchAddress !== undefined ){
                this.item.clu_address = this.addressTab[this.searchAddress]?.item?.properties?.name
                this.item.clu_town = this.addressTab[this.searchAddress]?.item?.properties?.city
                this.item.clu_zip = this.addressTab[this.searchAddress]?.item?.properties?.postcode
                this.item.clu_lat = this.addressTab[this.searchAddress]?.item?.geometry?.coordinates[1]
                this.item.clu_lng = this.addressTab[this.searchAddress]?.item?.geometry?.coordinates[0]
            }
        },
        getCaptcha(){
            this.captchaImageSrc = ''
            this.$http.customRequest({
                method: 'get', url: '../captcha/api/default', responseType: 'json'
            }).then( (response) => {
                if( response.status == 204 ){
                    this.$root.$toast({ color: 'warning', text: 'Le fichier image n\'existe pas' })
                } else {
                    this.captchaImageSrc = response.data.img
                    this.item.key = response.data.key
                    this.captchaReady = true
                }
            }).catch( (error) => {
                this.$root.$toast({ color: 'error', text: error })
            })
        },
    }
}
</script>

<style lang="css">
/* Personnalisation code */

</style>